import React, { useState } from "react"
import formStyles from "./inscriptionForm.module.scss"
import imgHero from "../../images/inscripcionHero.png"

let config = {
  ciclos: ["Enero 2020", "Agosto 2020"],
  cursos: ["Curso 1", "Curso 2"],
  pagos: ["Pago 1", "Pago 2"],
  cities: ["Monterrey", "Baja California"],
  promociones: ["Promocion 1", "Promocion 2"],
}

export default function Inscripcion() {
  const [ciclo, setCiclo] = useState("")
  const [curso, setCurso] = useState("")
  const [name, setName] = useState("")
  const [promocion, setPromocion] = useState("")
  const [pago, setPago] = useState("")
  const [cita, setCita] = useState("")
  const [state, setState] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [submiting, setSubmiting] = useState(false)

  const [focus, setFocus] = useState(false)

  function handleSubmit(e) {
    setSubmiting("submiting")
    e.preventDefault()
    const data = {
      ciclo: ciclo,
      curso: curso,
      name: name,
      promocion: promocion,
      pago: pago,
      cita: cita,
      state: state,
      email: email,
      phone: phone,
    }
    fetch("https://hook.integromat.com/ffo4vjitcksu1byzh7y3ublf2xn0w2nc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    }).then(response => {
      if (response.ok) {
        setSubmiting(true)
      } else setSubmiting(false)
    })
  }
  function handleFocus() {
    setFocus(true)
  }
  function handleBlur() {
    setFocus(false)
  }

  if (!submiting) {
    return (
      <div>
        <div className={formStyles.container}>
          <h1 className={formStyles.title}>INSCRIPCIÓN</h1>
          <form className={formStyles.form} onSubmit={handleSubmit}>
            <div className={formStyles.columnContainer}>
              <br />
              <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                name="name"
                className={formStyles.input}
                placeholder="Nombre"
                autoComplete="name"
                required
              />
              <input
                type="text"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                name="phone"
                className={formStyles.input}
                placeholder="Móvil"
                autoComplete="phone"
                required
              />
              <p className={formStyles.inputType}>Lugar de residencia</p>
              <select
                onChange={e => setState(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={[
                  formStyles.selectInput,
                  focus ? formStyles.focusedInput : formStyles.unfocusedInput,
                ].join(" ")}
              >
                <option disabled selected>
                  {" "}
                  -- Lugar de Residencia --{" "}
                </option>
                {config.cities.map(city => {
                  return <option value={city}>{city}</option>
                })}
              </select>
              <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                name="emial"
                className={formStyles.input}
                placeholder="Email"
                autoComplete="email"
                required
              />
            </div>

            <div className={formStyles.columnContainer}>
              <p className={formStyles.inputType}>Ciclo de ingreso</p>
              <select
                onChange={e => setCiclo(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={[
                  formStyles.selectInput,
                  focus ? formStyles.focusedInput : formStyles.unfocusedInput,
                ].join(" ")}
              >
                <option disabled selected>
                  {" "}
                  -- Selecciona un ciclo --{" "}
                </option>
                {config.ciclos.map(ciclo => {
                  return <option value={ciclo}>{ciclo}</option>
                })}
              </select>
              <select
                onChange={e => setCurso(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={[
                  formStyles.selectInput,
                  focus ? formStyles.focusedInput : formStyles.unfocusedInput,
                ].join(" ")}
              >
                <option disabled selected>
                  {" "}
                  -- Selecciona un curso --{" "}
                </option>
                {config.cursos.map(curso => {
                  return <option value={curso}>{curso}</option>
                })}
              </select>
              <select
                onChange={e => setPromocion(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={[
                  formStyles.selectInput,
                  focus ? formStyles.focusedInput : formStyles.unfocusedInput,
                ].join(" ")}
              >
                <option disabled selected>
                  {" "}
                  -- Selecciona una promoción --{" "}
                </option>
                {config.promociones.map(promocion => {
                  return <option value={promocion}>{promocion}</option>
                })}
              </select>
              <p className={formStyles.inputType}>Haciendo pago único</p>
              <select
                onChange={e => setPago(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={[
                  formStyles.selectInput,
                  focus ? formStyles.focusedInput : formStyles.unfocusedInput,
                ].join(" ")}
              >
                <option disabled selected>
                  {" "}
                  -- Selecciona una opción --{" "}
                </option>
                {config.pagos.map(pago => {
                  return <option value={pago}>{pago}</option>
                })}
              </select>
              <p className={formStyles.inputType}>
                Pago en línea o solicitar cita
              </p>
              <select
                onChange={e => setCita(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={[
                  formStyles.selectInput,
                  focus ? formStyles.focusedInput : formStyles.unfocusedInput,
                ].join(" ")}
              >
                <option disabled selected>
                  {" "}
                  -- Selecciona una forma de pago --{" "}
                </option>
                <option value="Pago en linea">Pago en línea</option>
                <option value="Solicitar cita">Solicitar cita</option>
              </select>
              <button
                type="submit"
                className={[
                  formStyles.submitButton,
                  submiting ? formStyles.submiting : "",
                ].join(" ")}
              >
                Solicitar Informes
              </button>
            </div>
          </form>
        </div>
        <div className={formStyles.imgContainer}>
          <img className={formStyles.img} src={imgHero} alt="heroIMg" />
        </div>
      </div>
    )
  } else if (submiting === "submiting") {
    return (
      <div className={formStyles.thankYouContainer}>
        <h1 className={formStyles.thankYouTitle} style={{ width: "50%" }}>
          Espera un momento {name}, tus datos se están enviando
        </h1>
      </div>
    )
  } else if (submiting) {
    return (
      <div className={formStyles.thankYouContainer}>
        <h1 className={formStyles.thankYouTitle} style={{ width: "50%" }}>
          Gracias {name}, tus datos han sido enviados
        </h1>
        <p className={formStyles.thankYouText}>
          Por favor verifica que tu información sea correcta:
          <br />
          <br />
          <strong>Nombre</strong>: {name}
          <br />
          <strong>Móvil</strong>: {phone}
          <br />
          <strong>Lugar de Residencia</strong>: {state}
          <br />
          <strong>Email</strong>: {email}
          <br />
          <strong>Ciclo de interés</strong>: {ciclo}
          <br />
          <strong>Curso</strong>: {curso}
          <br />
          <strong>Promoción</strong>: {promocion}
          <br />
          <strong>Pago</strong>: {pago}
          <br />
          <strong>Forma de pago</strong>: {cita}
        </p>
        <button
          className={formStyles.tysubmitButton}
          onClick={() => setSubmiting(false)}
        >
          Corregir
        </button>
      </div>
    )
  }
}
