import React from "react"
import Layout from '../components/layout/layout'
import InscriptionForm from "../components/inscription/inscriptionForm"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Inscripcions"
      description="Insríbete a un curso presencial."
      // image={`https://www.ppdc.mx${blog}`}
      url="/inscripciones"
    />
    <InscriptionForm />
  </Layout>
)
